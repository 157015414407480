<template>
  <div class="capture-body pointer-events-none">
    <div class="vx-row -mx-2 flex-nowrap">
      <div class="vx-col px-2 w-16 pt-20">
        <div class="rounded-full overflow-hidden mb-5" style="width: 32px; heigth: 32px;">
          <ContentLoader class="block" width="32" height="32"></ContentLoader>
        </div>
        <div class="rounded-full overflow-hidden" style="width: 32px; heigth: 32px;">
          <ContentLoader class="block" width="32" height="32"></ContentLoader>
        </div>
      </div>
      <div class="vx-col px-2 flex-1">
        <ul class="flex mb-base">
          <li>
            <vs-button type="line" icon-pack="feather" icon="icon-image">Diff</vs-button>
          </li>
          <li class="ml-2">
            <vs-button type="line" icon-pack="feather" icon="icon-tag">Split</vs-button>
          </li>
        </ul>

        <div class="vx-tabs">
          <div class="vx-tab__content">
            <div class="vx-row -mx-3 w-full">
              <div class="vx-col flex-1 px-3">
                <div class="mb-2 flex justify-between items-center">
                  <p class="text-xs flex items-center">
                    <span class="font-semibold uppercase">Capture 1:</span>
                    <vs-icon icon-pack="feather" icon="icon-clock" class="text-xs ml-2 mr-1" />
                    <span style="width: 120px; height: 18px;"><ContentLoader height="18" width="120"></ContentLoader></span>
                  </p>
                </div>
                <div class="relative">
                  <ContentLoader class="block" height="260"></ContentLoader>
                </div>
              </div>
              <div class="vx-col flex-1 px-3">
                <div class="mb-2 flex justify-between items-center">
                  <p class="text-xs flex items-center">
                    <span class="font-semibold uppercase">Capture 2:</span>
                    <vs-icon icon-pack="feather" icon="icon-clock" class="text-xs ml-2 mr-1" />
                    <span style="width: 120px; height: 18px;"><ContentLoader height="18" width="120"></ContentLoader></span>
                  </p>
                </div>
                <div class="relative">
                  <ContentLoader class="block" height="260"></ContentLoader>
                </div>
              </div>
              <div class="vx-col flex-1 px-3">
                <div class="mb-2 flex justify-between items-center">
                  <p class="text-xs">
                    <span class="font-semibold uppercase">The Difference</span>
                  </p>
                </div>
                <div class="relative">
                  <ContentLoader class="block" height="260"></ContentLoader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';
export default {
  components: {
    ContentLoader
  }
};
</script>
