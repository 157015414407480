<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="capture-view-area data-list-container">
    <div class="capture-header mb-base">
      <div class="text-sm">
        <a :href="item.url | urlPath" target="_blank">
          <vs-icon icon-pack="feather" icon="icon-link" />
          {{ item.url | urlPath }}
        </a>
        <p>
          <vs-icon icon-pack="feather" icon="icon-clock" />
          {{ item.dateCaptured | moment }}
        </p>
      </div>
    </div>
    <LoaderCaptureCompare></LoaderCaptureCompare>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex';
import LoaderCaptureCompare from '@/views/captures/components/loader/CaptureCompare';

export default {
  components: {
    LoaderCaptureCompare
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('captures', ['item', 'comparing'])
  },
  methods: {},
  async created() {},
  mounted() {
    this.$nextTick(async () => {
      const { id, captureId } = this.$route.params;
      const result = await Promise.all([
        ...(Number(id) === this.item.id
          ? []
          : [this.$store.dispatch('captures/item', { id })]),
        ...(Number(captureId) === this.comparing.id
          ? []
          : [
              this.$store.dispatch('captures/item', {
                id: captureId,
                comparing: true
              })
            ])
      ]);
      this.$router.push(`/captures/${id}/compare/${captureId}`);
    });
  }
};
</script>

<style lang="scss">
.btn-viewcompare {
  &.includeIconOnly {
    padding: 0 !important;
    width: 28px !important;
    height: 28px !important;
  }
}
</style>
